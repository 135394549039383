var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.title,"width":1000,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-descriptions',{attrs:{"title":"基本信息","column":3}}),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 14 }}},[_c('a-form-item',{attrs:{"label":"所属目录"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cata']),expression:"['cata']"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"Excel模板"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'excel',
          { rules: [{ required: true, message: 'Excel模板不能为空' }] },
        ]),expression:"[\n          'excel',\n          { rules: [{ required: true, message: 'Excel模板不能为空' }] },\n        ]"}],attrs:{"name":"file","multiple":false,"file-list":_vm.fileList,"remove":() => false,"before-upload":() => false,"placeholder":"请上传Excel模板"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传文件 ")],1)],1)],1),_c('a-form-item',{staticStyle:{"visibility":"hidden","margin":"0"},attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sort',
          { rules: [{ required: true, message: '排序不能为空' }] },
        ]),expression:"[\n          'sort',\n          { rules: [{ required: true, message: '排序不能为空' }] },\n        ]"}],attrs:{"type":"number","placeholder":"请输入排序","disabled":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }