<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-05 22:13:52
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-14 23:59:08
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\templateManage\RuleModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="1000"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-descriptions title="基本信息" :column="3"> </a-descriptions>
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="所属目录">
        <a-input v-decorator="['cata']" disabled />
      </a-form-item>
      <a-form-item label="Excel模板">
        <a-upload
          name="file"
          :multiple="false"
          :file-list="fileList"
          :remove="() => false"
          :before-upload="() => false"
          v-decorator="[
            'excel',
            { rules: [{ required: true, message: 'Excel模板不能为空' }] },
          ]"
          placeholder="请上传Excel模板"
          @change="handleChange"
        >
          <a-button>
            <a-icon type="upload" />
            上传文件
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="排序" style="visibility: hidden; margin: 0">
        <a-input
          type="number"
          v-decorator="[
            'sort',
            { rules: [{ required: true, message: '排序不能为空' }] },
          ]"
          placeholder="请输入排序"
          disabled
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { uploadTmp } from "@/api/template";
import { indexStandardType, taxpayerNature, dataType } from "@/utils/dict";
import { getLabel } from "@/utils/utils";
export default {
  name: "ruleModal",
  data() {
    return {
      status: 1,
      visible: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      pid: "",
      parents: [],
      fileList: [],
    };
  },
  methods: {
    handleChange(info) {
      let fileList = info.fileList;
      fileList = fileList.slice(-1);
      this.fileList = fileList;
    },
    async show(status, pid, parents, sort) {
      this.pid = pid;
      this.parents = parents;
      this.status = status;
      this.visible = true;
      let paremtsArr = [];
      parents.forEach((item) => {
        paremtsArr.push(item.menuName);
      });
      let parentsStr = paremtsArr.reverse().join("/");
      this.$nextTick(() => {
        this.form.setFieldsValue({ cata: parentsStr, sort: sort });
      });
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let parents = this.parents;
          let len = parents.length;
          let tabExcelVo = {
            year: len == 6 ? parents[4].menuName : parents[3].menuName,
            taxpayerNature:
              len == 6
                ? getLabel(
                    parents[3].menuName,
                    taxpayerNature,
                    "label",
                    "value"
                  )
                : -2,
            indexStandardType:
              len == 6
                ? getLabel(
                    parents[2].menuName,
                    indexStandardType,
                    "label",
                    "value"
                  )
                : -2,
            dateType: getLabel(parents[1].menuName, dataType, "label", "value"),
            excelName: parents[0].menuName,
          };
          let file = values.excel.file;
          let fileName = file.name;
          let formData = new FormData();
          formData.append("file", file, fileName);
          let query = Object.assign(
            {},
            tabExcelVo,
            { parentId: this.pid },
            { sort: values.sort }
          );
          let res = await uploadTmp(formData, query);
          if (res.status == 200) {
            this.$message.success(
              this.status == 2 ? "添加成功！" : "修改成功！"
            );
            this.fileList = [];
            this.form.resetFields();
            this.visible = false;
            this.$emit("reload");
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
    handleCancel() {
      this.fileList = [];
      this.form.resetFields();
      this.visible = false;
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 2:
          title = "添加Excel模板";
          break;
        case 3:
          title = "编辑";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-upload-list-item-card-actions {
  display: none;
}
.btns {
  margin-bottom: 10px;
}
</style>
