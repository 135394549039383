/*
 * @Author: zhangjingqing
 * @Date: 2022-05-06 16:42:01
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-07 10:59:34
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\template.js
 */
import { postAction, downFile } from "./http/manage";
const URL = "";

// 删除excel模板及关联信息
const delTmp = (params) => postAction(URL + "/tax-model/del-data", params);
// 模板下载
const dowloadTmp = (params) => downFile(URL + "/tax-model/dowload", params);
// 税务-距离上一次检测天数
const getLstExm = (params) => postAction(URL + "/tax-model/lst-exm", params);
// 上传文件，返回文件path
const uploadTmp = (params, query) =>
  postAction(
    URL +
      "/tax-model/taxUploadFile?year=" +
      query.year +
      "&dateType=" +
      query.dateType +
      "&indexStandardType=" +
      query.indexStandardType +
      "&taxpayerNature=" +
      query.taxpayerNature +
      "&excelName=" +
      query.excelName +
      "&parentId=" +
      query.parentId +
      "&sort=" +
      query.sort,
    params
  );

export { delTmp, dowloadTmp, getLstExm, uploadTmp };
